.Notices {
    margin: 0;
    padding: 0;
    &__notice {
        list-style: none;
        margin-bottom: 0rem;
        line-height: 1.1;

    }
    &__featured {
        background: $color-orange;
        padding: 3px 4px;
        text-transform: uppercase;
        color: white;
        line-height: 1;
        display: inline-block;
        margin: 0;
        border-radius: 4px;
        letter-spacing: 1px;
        letter-spacing: 0.4px;
        font-weight: bold;
        @include font-size(11px);
        margin-right: 6px;
    }
    &__featured + &__title {
        line-height: 1.4;
    }
    &__featured + &__title + &__metadata {
        margin-top: 1px;
    }    
    &__title {
        margin: 0;
        @include font-size(14px);    
        line-height: 1.3;
        display: inline;
    }
    &__metadata {
        @include font-size(12px);    
        margin-top: 4px;
        line-height: 1.4;
        @include themify($themes) {
            color: themed('textColorSecondary');
        }                  
    }
    &__author-link {
        @extend .link;
        @extend .link--secondary;
    }    
    &__title-link {
        @extend .link;
        @extend .link--primary;
    }        
    &__by {
        text-transform: capitalize;
    }
}