  .flag__button {
    cursor: pointer;
    .flag2 {
      display: none !important;
      visibility: hidden;
    }
    &:hover {
      .flag1 {
        display: none !important;
        visibility: hidden;
      }
      .flag2 {
        visibility: visible;
        display: inline-block !important;
      }
      svg {
        fill: red;
      }
    }
  }

  .flag__button--post {
    position: absolute;
    right: 1em;
  }
