.Markdown {
  font-family: 'Source Serif Pro', serif;
  font-size: 120%;

  line-height: 150%;
}

// used for comments
.Markdown.MarkdownViewer--small {
  font-family: inherit;
  font-size: 110%;

  img {
    max-width: 400px;
    max-height: 400px;
  }

  div.videoWrapper {
    max-width: 480px;
    padding-bottom: 270px;
  }
}

.Markdown {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: none;

  h1, h2, h3, h4, h5, h6 {
    font-family: $body-font-family;
    font-weight: 600;
  }

  h1 {
    margin: 2.5rem 0 .3rem;
    font-size: 160%
  }
  h2 {
    margin: 2.5rem 0 .3rem;
    font-size: 140%;
  }
  h3 {
    margin: 2rem 0 0.3rem;
    font-size: 120%;
  }
  h4 {
    margin: 1.5rem 0 0.2rem;
    font-size: 110%;
  }
  h5 {
    margin: 1rem 0 0.2rem;
    font-size: 100%;
  }
  h6 {
    margin: 1rem 0 0.2rem;
    font-size: 90%;
  }

  code {
    padding: 0.2rem;
    font-size: 0.90rem;
    line-height: 1.2rem;
    border-radius: 3px;
    border: none;
    font-weight: inherit;
    overflow: scroll;
    @include themify($themes) {
      color: themed('htmlCodeColor');
      background-color: themed('htmlCodeBackgroundColor');
    }
  }

  pre > code {
    display: block;
    overflow-y: hidden;
    overflow-x: auto;
  }

  strong {
    font-weight: 600;
  }

  ol, ul {
    margin-left: 2rem;
  }

  table td {
    word-break: normal; // issue #146
  }

  table thead th {
    word-break: normal;
  }

  p {
    font-size: 100%;
    line-height: 150%;
    margin: 0 0 1.5rem 0;
  }
  a {
    @extend .link;
    @extend .link--accent;
  }

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: none;
    margin-bottom: 10px;
  }

  iframe {
    max-width: 100%;
    max-height: 75vw;
  }

  iframe.truvvl-iframe {
    max-height: initial;
  }

  .tweetWrapper iframe {
      max-height: inherit;
  }

  div.pull-right {
    float: right;
    padding-left: 1rem;
    max-width: 50%;
  }

  div.pull-left {
    float: left;
    padding-right: 1rem;
    max-width: 50%;
  }

  div.text-justify {
    text-align: justify;
  }

  div.text-right {
    text-align: right;
  }

  div.text-center {
    text-align: center;
  }

  div.text-rtl {
    direction: rtl;
  }

  div.videoWrapper {
    position: relative;
    text-align: center;

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    blockquote {
      border-left: none;
    }
  }
  div.iframeWrapper {
    blockquote {
      border-left: none;
    }
  }
  div.tweetWrapper {
    padding-bottom: 0;

    blockquote {
      border-left: none;
    }
  }
  blockquote {
    @include themify($themes) {
      border-left: themed('borderDark');
    }
  }
  blockquote > p {
    @include themify($themes) {
      color: themed('textColorSecondary');
    }
  }
}


