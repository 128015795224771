

$color-white: #fff;
$color-black: #000;
$color-red: #ff0264;
$color-blue: #004EFF;
$color-blue-original-dark: #1A5099;
$color-blue-original-light: #4BA2F2;
$color-hive-black: #212529;
$color-hive-black-darkest: #11161A;
$color-blue-dark: #2C3A45;
$color-hive-red: #E31337;
$color-hive-red-light:rgb(230, 19, 54);
$color-hive-red-dark:rgb(216, 18, 51);

$color-yellow: #fce76c;
$color-orange: #F76900;
$color-transparent: transparent;

$color-background-almost-white:#fafafa;
$color-background-almost-white-darker:#eaeaef;
$color-background-off-white: #f8f8f8;
$color-background-off-white-dark: #f4f4fd;
$color-background-off-white-darker: #f0f0f09;
$color-background-dark: $color-hive-black;
$color-background-dark-lighter: #313539;
$color-background-less-dark: #2c3136;
$color-background-super-dark: #191c1f;

$color-text-dark: #333;
$color-text-white: #fcfcfc;
$color-text-blue: #004EFF;
$color-text-gray: #788187;
$color-text-gray-light: #A6B2BA;
$color-text-hive-red: #E31337;
$color-text-red: $color-red;

$color-border-light: #eee;
$color-border-less-light: #e5e5e5;
$color-border-light-lightest: #f6f6f6;
$color-border-dark: $color-hive-black;
$color-border-dark-lightest: #2c3136;

$font-primary: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;

$alert-color: $color-red;
