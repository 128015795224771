/*! GitHub: One Dark */
/* adapted from: https://github.com/atom/one-dark-syntax & https://github.com/Aerobird98/codemirror-one-dark-theme */
/* by https://github.com/sparcut */
:root {
    --ghd-code-background: hsl(0, 0%, 8%);
    --ghd-code-color: hsl(220,14%,71%);
}
/* comment, punctuation.definition.comment, string.comment */
.theme-dark .gist .pl-c, .pl-c span { color: hsl(220,10%,40%); font-style: italic; }
/* constant, entity.name.constant, variable.other.constant, variable.language,
support, meta.property-name, support.constant, support.variable,
 meta.module-reference, markup.raw, meta.diff.header */
.theme-dark .gist .pl-c1 { color: hsl(29,54%,61%); }
/* string.regexp constant.character.escape */
.theme-dark .gist .pl-sr .pl-cce { color: hsl(187,47%,55%); font-weight: normal; }
.theme-dark .gist .pl-cn { color: hsl(29,54%,61%); }
.theme-dark .gist .pl-e { color: hsl(29,54%,61%); } /* entity */
.theme-dark .gist .pl-ef { color: hsl(207,82%,66%); } /* entity.function */
.theme-dark .gist .pl-en { color: hsl(29,54%,61%); } /* entity.name */
.theme-dark .gist .pl-enc { color: hsl(39,67%,69%); } /* entity.name.class */
.theme-dark .gist .pl-enf { color: hsl(207,82%,66%); } /* entity.name.function */
.theme-dark .gist .pl-enm { color: hsl(220,14%,71%); } /* entity.name.method-name */
.theme-dark .gist .pl-ens { color: hsl(5,48%,51%); } /* entity.name.section */
.theme-dark .gist .pl-ent { color: hsl(355,65%,65%); } /* entity.name.tag */
.theme-dark .gist .pl-entc { color: hsl(39,67%,69%); } /* entity.name.type.class */
.theme-dark .gist .pl-enti { color: hsl(187,47%,55%); } /* entity.name.type.instance */
.theme-dark .gist .pl-entm { color: hsl(355,65%,65%); } /* entity.name.type.module */
.theme-dark .gist .pl-eoa { color: hsl(29,54%,61%); } /* entity.other.attribute-name */
.theme-dark .gist .pl-eoac { color: hsl(29,54%,61%); } /* entity.other.attribute-name.class */
.theme-dark .gist .pl-eoac .pl-pde { color: hsl(29,54%,61%); } /* punctuation.definition.entity */
.theme-dark .gist .pl-eoai { color: hsl(207,82%,66%); } /* entity.other.attribute-name.id */
.theme-dark .gist .pl-eoi { color: hsl(95,38%,62%); } /* entity.other.inherited-class */
.theme-dark .gist .pl-k { color: hsl(286,60%,67%); } /* keyword, storage, storage.type */
.theme-dark .gist .pl-ko { color: hsl(220,14%,71%); } /* keyword.operator */
.theme-dark .gist .pl-kolp { color: hsl(286,60%,67%); } /* keyword.operator.logical.python */
.theme-dark .gist .pl-kos { color: hsl(207,82%,66%); } /* keyword.other.special-method */
.theme-dark .gist .pl-kou { color: hsl(29,54%,61%); } /* keyword.other.unit */
.theme-dark .gist .pl-mai .pl-sf { color: hsl(187,47%,55%); } /* support.function */
.theme-dark .gist .pl-mb { color: hsl(29,54%,61%); font-weight: bold; } /* markup.bold */
.theme-dark .gist .pl-mc { color: hsl(286,60%,67%); } /* markup.changed, punctuation.definition.changed */
.theme-dark .gist .pl-mh { color: hsl(355,65%,65%); } /* markup.heading */
/* markup.heading punctuation.definition.heading */
.theme-dark .gist .pl-mh .pl-pdh { color: hsl(207,82%,66%); }
.theme-dark .gist .pl-mi { color: hsl(286,60%,67%); font-style: italic; } /* markup.italic */
.theme-dark .gist .pl-ml { color: hsl(187,47%,55%); } /* markup.list */
.theme-dark .gist .pl-mm { color: hsl(29,54%,61%); } /* meta.module-reference */
.theme-dark .gist .pl-mp { color: hsl(220,9%,55%); } /* meta.property-name */
.theme-dark .gist .pl-mp1 .pl-sf { color: hsl(220,14%,71%); } /* meta.property-value support.function */
.theme-dark .gist .pl-mq { color: hsl(29,54%,61%); } /* markup.quote */
.theme-dark .gist .pl-mr { color: hsl(207,82%,66%); } /* meta.require */
.theme-dark .gist .pl-ms { color: hsl(220,14%,71%); } /* meta.separator */
/* punctuation.definition.bold */
.theme-dark .gist .pl-pdb { color: hsl(39,67%,69%); font-weight: bold; }
/* punctuation.definition.comment */
.theme-dark .gist .pl-pdc { color: hsl(220,10%,40%); font-style: italic; }
.theme-dark .gist .pl-pdc1 { color: hsl(220,14%,71%); } /* punctuation.definition.constant */
.theme-dark .gist .pl-pde { color: hsl(286,60%,67%); } /* punctuation.definition.entity */
/* punctuation.definition.italic */
.theme-dark .gist .pl-pdi { color: hsl(286,60%,67%); font-style: italic; }
/* punctuation.definition.string, source.regexp, string.regexp.character-class */
.theme-dark .gist .pl-pds { color: hsl(95,38%,62%); }
.theme-dark .gist .pl-pdv { color: hsl(355,65%,65%); } /* punctuation.definition.variable */
/* string punctuation.section.embedded source */
.theme-dark .gist .pl-pse .pl-s1 { color: hsl(95,38%,62%); }
.theme-dark .gist .pl-pse .pl-s2 { color: hsl(39,67%,69%); } /* punctuation.section.embedded source */
.theme-dark .gist .pl-s { color: hsl(95,38%,62%); } /* string */
.theme-dark .gist .pl-s1 { color: hsl(95,38%,62%); } /* string */
.theme-dark .gist .pl-s2 { color: hsl(39,67%,69%) ; } /* source */
.theme-dark .gist .pl-mp .pl-s3 { color: hsl(29,54%,61%); } /* support */
.theme-dark .gist .pl-s3 { color: hsl(29,54%,61%); } /* support */
.theme-dark .gist .pl-sc { color: hsl(39,67%,69%); } /* support.class */
.theme-dark .gist .pl-scp { color: hsl(220,14%,71%); } /* support.constant.property-value */
.theme-dark .gist .pl-sf { color: hsl(187,47%,55%); } /* support.function */
.theme-dark .gist .pl-smc { color: hsl(220,14%,71%); } /* storage.modifier.c */
/* variable.parameter.function, storage.modifier.package,
 storage.modifier.import, storage.type.java, variable.other */
.theme-dark .gist .pl-smi { color: hsl(355,65%,65%); }
.theme-dark .gist .pl-smp { color: hsl(39,67%,69%); } /* storage.modifier.package */
.theme-dark .gist .pl-sok { color: hsl(29,54%,61%); } /* support.other.keyword */
.theme-dark .gist .pl-sol { color: hsl(355,65%,65%); } /* string.other.link */
.theme-dark .gist .pl-som { color: hsl(220,14%,71%); } /* support.other.module */
.theme-dark .gist .pl-sr { color: hsl(187,47%,55%); } /* string.regexp */
/* string.regexp string.regexp.arbitrary-repitition */
.theme-dark .gist .pl-sr .pl-sra { color: hsl(187,47%,55%); }
.theme-dark .gist .pl-src { color: hsl(187,47%,55%); } /* string.regexp.character-class */
.theme-dark .gist .pl-sr .pl-sre { color: hsl(39,67%,69%); } /* string.regexp source.ruby.embedded */
.theme-dark .gist .pl-st { color: hsl(187,47%,55%); } /* support.type */
.theme-dark .gist .pl-stj { color: hsl(39,67%,69%); } /* storage.type.java */
.theme-dark .gist .pl-stp { color: hsl(220,9%,55%); } /* support.type.property-name */
.theme-dark .gist .pl-sv { color: hsl(29,54%,61%); } /* support.variable */
.theme-dark .gist .pl-v { color: hsl(39,67%,69%); } /* variable */
.theme-dark .gist .pl-vi { color: hsl(5,48%,51%); } /* variable.interpolation */
.theme-dark .gist .pl-vo { color: hsl(187,47%,55%); } /* variable.other */
.theme-dark .gist .pl-vpf { color: hsl(220,14%,71%); } /* variable.parameter.function */
/* markup.inserted, meta.diff.header.to-file, punctuation.definition.inserted */
.theme-dark .gist .pl-mi1 { color: hsl(95,38%,62%); background: #020; }
/* meta.diff.header.to-file */
.theme-dark .gist .pl-mdht { color: hsl(95,38%,62%); background: #020; }
/* markup.deleted, meta.diff.header.from-file, punctuation.definition.deleted */
.theme-dark .gist .pl-md { color: hsl(355,65%,65%); background: #200; }
/* meta.diff.header.from-file */
.theme-dark .gist .pl-mdhf { color: hsl(355,65%,65%); background: #200; }
/* meta.diff.range */
.theme-dark .gist .pl-mdr { color: hsl(220,14%,71%); font-weight: normal; }
.theme-dark .gist .pl-mdh { color: hsl(355,65%,65%); font-weight: normal; } /* meta.diff.header */
.theme-dark .gist .pl-mdi { color: hsl(355,65%,65%); font-weight: normal; } /* meta.diff.index */
/* constant.other.reference.link, string.other.link */
.theme-dark .gist .pl-corl { color: hsl(355,65%,65%); text-decoration: underline; }
.theme-dark .gist .pl-ib { background-color: hsl(355,65%,65%); } /* invalid.broken */
.theme-dark .gist .pl-bu, /* invalid.broken, invalid.deprecated, invalid.unimplemented, message.error, brackethighlighter.unmatched, sublimelinter.mark.error */
.theme-dark .gist .pl-ii { background-color: hsl(0,70%,60%); } /* invalid.illegal */
.theme-dark .gist .pl-mo { color: hsl(220,14%,71%); } /* meta.output */
.theme-dark .gist .pl-mri { color: hsl(95,38%,62%); } /* markup.raw.inline */
.theme-dark .gist .pl-ms1 { color: hsl(220,14%,71%); background-color: #373b41; } /* meta.separator */
.theme-dark .gist .pl-va { color: hsl(220,14%,71%); } /* variable.assignment */
.theme-dark .gist .pl-vpu { color: hsl(220,14%,71%); } /* variable.parameter.url */
.theme-dark .gist .pl-entl { color: hsl(355,65%,65%); } /* entity.name.tag.label */
.theme-dark .gist .pl-token.active, .pl-token:hover { background: hsl(207,82%,66%); color: hsl(0, 0%, 8%); }
.theme-dark .gist .blob-code, .theme-dark .gist .blob-code-inner { color: hsl(220,14%,71%) }
