.App {
  min-height: 100vh;
  padding-top: 0px;
}

.App__content {
  margin-top: 1rem;
}

.welcomeWrapper {
  padding-bottom: 1rem;
}

.RightMenu {
  background-color: #555;
  height: 100vh;
  color: #fff;
  padding-top: 3rem;
  .close-button {
    color: #fff;
  }
  .menu > li {
    > a {
      color: #fff;
      border-top: 1px solid #777;
    }
    > a:hover {
      background-color: #666;
    }
  }
  .menu > li.last {
    border-bottom: 1px solid #777;
  }
  .button.hollow {
    color: #fff;
    border: none;
  }
}

.PlainLink {
    @extend .link;
    @extend .link--secondary;
}

.text-muted {
    @include themify($themes) {
        color: themed('textColorSecondary');
        svg {
            fill: themed('textColorSecondary');
            opacity: 0.7;
        }
    }
}

.welcomeBanner {
  margin-top: -1rem;
  padding: 0;
  background-color: $color-hive-black;
  color: $color-white;
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

  h2 {
    font-weight: bold;
    margin-bottom: 16px;
    @include font-size(28px);
    line-height: 1;
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
    @include opacity(0);
    @include MQ(M) {
      @include font-size(34px);
      max-width: 280px;
    }
  }

  h4 {
    color: $color-white;
    font-weight: normal;
    margin-bottom: 1rem;
    width: 85%;
    max-width: 360px;
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.3 !important;
    @include font-size(16px);
    @include opacity(0);
    @include MQ(M) {
      @include font-size(17px);
    }
  }

  .row {
    align-items: center;
  }

  .button {
    min-width: 120px;
    white-space: nowrap;
    text-decoration: none;
    font-weight: bold;
    transition: 0.2s all ease-in-out;
    text-transform: initial;
    border-radius: 0;
    background-color: $color-white;
    color: $color-hive-black;
    border: none;
    box-shadow: 0px 0px 0px 0 rgba(0,0,0,0), 2px 2px 0 0 $color-hive-red;
    padding: 16px;
    margin-right: 18px;
    @include font-size(16px);
    cursor: pointer;
    font-family: $font-primary;
    &:hover, &:focus {
      background-color: $color-hive-red;
      color: $color-white;
      box-shadow: 0px 0px 0px 0 rgba(0,0,0,0.1), 4px 4px 0 0 $color-white;
      text-shadow: 0 1px 0 rgba(0,0,0,0.20);
    }
    @include MQ(M) {
      @include font-size(18px);
      min-width: 132px;
    }
  }

  .button--primary {
    @include opacity(0);
  }
  .close-button {
    top: 0.8rem;
    right: 0;
    @include MQ(M) {
      top: 0.5rem;
    }
  }

  .button.ghost {
    background: transparent;
    color: $color-white;
    border: 1px solid #30414A;
    box-shadow: 0px 0px 0px 0 #30414A, 2px 2px 0 0 #30414A;
    @include opacity(0);
    &:hover, &:focus {
      box-shadow: 0px 0px 0px 0 #30414A, 4px 4px 0 0 $color-hive-red;
    }
  }

  .heroImage {
    max-height: 360px;
    @include opacity(0);
    animation: fade-in-up 0.6s ease-out both;
    @media screen and (prefers-reduced-motion) {
      animation: none;
      @include opacity(1);
    }
  }

  .welcomeImage {
    padding: 1em 0;
    @include MQ(L) {
      padding: 2em 0;
    }
  }

  .welcomePitch {
    padding: 20px;
    @include MQ(M) {
      padding: 0;
    }
  }
}

.downvoted {
    opacity: 0.5;
    //-webkit-filter: grayscale(1); // image
    //filter: gray; // image grayscale
    transition: 0.2s all ease-in-out;
    color: #848282;
    @include themify($themes) {
        color: themed('textColorPrimary');
    }
    .Comment__header-user {
      color: #848282;
      @include themify($themes) {
          color: themed('textColorPrimary');
      }
    }
}
.downvoted:hover {
    opacity: 1;
    filter: none;
    -webkit-filter: none;
}

.App__announcement {
  padding-right: 40px;
  padding-top: 40px;
  .close-button {
    right: 0;
  }
}


.beta-disclaimer {
    @include themify($themes) {
        padding: 8px 24px;
        font-size: 0.8em;
        color: themed('textColorPrimary');
        background: repeating-linear-gradient(
          45deg,
          themed('backgroundColor'),
          themed('backgroundColor') 10px,
          themed('moduleBackgroundColor') 10px,
          themed('moduleBackgroundColor') 20px
        );
    }
}

.react-tooltip-lite {
    background: #333;
    color: white;
}

.react-tooltip-lite-arrow {
    border-color: #333;
}
