// breakpoints
   
$S: 20em;  // 320px / 16
$FM: 40em; // Conform to foundation 'medium' css breakpoint
$M: 47.5em;  // 760px / 16
$FL: 64em; // Conform to foundation 'large' css breakpoint
$L: 75em; // 1200px / 16

$XL: 100em; //  1600px / 16

// media queries

@mixin MQ($canvas) {
  @if $canvas == S {
   @media only screen and (min-width: $S) { @content; } 
  }
  @if $canvas == FM {
    @media only screen and (min-width: $FM) { @content; } 
  }
  @else if $canvas == M {
   @media only screen and (min-width: $M) { @content; } 
  }
  @else if $canvas == FL {
    @media only screen and (min-width: $FL) { @content; } 
  }
  @else if $canvas == L {
   @media only screen and (min-width: $L) { @content; } 
  }
  @else if $canvas == XL {
    @media only screen and (min-width: $XL) { @content; } 
  }
}

// Foundation-like Classes
.show-for-mq-large {
    display: none;
    @include MQ(L) {
        display: inline;
    }
}

.hide-for-mq-large {
    display: inline;
    @include MQ(L) {
      display: none;
    }
}

.hide-for-mq-medium {
    display: inline;
    @include MQ(M) {
        display: none;
    }
}

.show-for-mq-medium {
    display: none;
    @include MQ(M) {
        display: inline;
    }
}