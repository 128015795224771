.PostCategoryBanner {
  margin: .5em 1em 1em 0;
  display: inline;

  .postTo {
    small {
      font-size: 100%;
      color: #666;
      .smallLabel {
        color: #333;
        font-weight: bold;
      }
    }
  }

  .categoryName {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  h3 {
    line-height: normal !important;
    padding: 0 0 0 .5em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.PostCategoryBanner--community-selector {
    display: inline;
    width: 200px;
}
