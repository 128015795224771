.logo {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    .logo__steemit{
        fill: $color-hive-red;
        transition: 0.25s all ease-in-out;
    }
    // Fixes #2639 - Don't apply hover styles on devices that do not support it.
    @media (hover: hover) {
        &:hover, &:active {
            .logo__steemit{
                @include themify($themes) {
                    fill: themed('colorAccentReverse');
                }
            }
        }
    }
}
