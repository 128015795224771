.LoginForm {
  max-width: 28rem;
  margin: 1rem auto 0.5rem auto;
  label {
    text-transform: none;
  }
  form {
    margin-top: 1.5rem;
  }
  .buttons {
    text-align: center;
  }
}
.pdf-download {
  margin-top: 1em;
  margin-bottom: 1em;
  button {
    color: #1A5099;
    text-transform: initial;
    cursor: pointer;
  }
}
.sign-up {
  .button {
    background-color: transparent;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.2rem;
    text-transform: none;
  }
  .button.hollow {
    border: 1px solid #ddd;
    color: $color-hive-black;
    transition: 0.2s all ease-in-out;
    @include font-size(16px);

    &:hover {
      border: 1px solid $color-hive-red-dark;
      color: $color-hive-red-dark;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
    }
  }
  em {
    font-weight: bold;
    font-style: normal;
  }
  hr {
      margin: 1.75rem auto 2rem auto;
  }
  p {
      margin-bottom: 1rem;
  }
}

.LoginForm__save-login {
  margin-top: 0.5rem;
}

.divider{
  display: flex;
  align-items: center;
  font-size: 14px;
}
.divider::before,.divider::after{
  content: '';
  flex: 1;
  height: 1px;
  background: #ccc;
}
.divider::before{
  margin-right: 10px;
}
.divider::after{
  margin-left: 10px;
}

#btn-hivesigner {
  color: #fff;
  border-color: #d1d5da;
  background: #d1d5da;

  &:hover {
    background: #A6A6A6;
  }

  img {
    width: 140px;
  }
}
.reveal .login-alternative-methods a.button {
    font-size: 15px;
}
.reveal .login-alternative-methods a.button img {
    height: 28px;
}
.hiveauth_info {
    margin-bottom: 10px;
}
.hiveauth_instructions,
.hiveauth_qr {
    display: none;
    &.show {
        display: block;
    }
}

.oauth-client-logo {
    margin-bottom: 1em;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    min-width: 1em;
    display: block;
}

.center {
    margin-left: auto;
    margin-right: auto;
}

.text-align-center {
    text-align: center;
}
