.CommunityRoles {
    table tbody {background: transparent;}
    table tbody tr {background: transparent !important;}
}

.community-user--role {
  cursor: pointer;
}

a.community--subscribe:hover {
    &.hollow {
        span {display: none}
        &::after {
            content: 'Leave';
        }
    }
}
