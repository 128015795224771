.vote_weights {
    opacity: 0.75;
    position: absolute;
    right: 0.5rem;
    color: green;
    span {color: red;}
}

ul.PostsList__summaries {
  margin-left: 0;
}

ul.PostsList__summaries > li {
  padding: 0.1em 0.5em 0 0.5em;
  margin-bottom: 0.8em;

  @include themify($themes) {
    border-radius: themed('roundedCorners');
    border: themed('border');
    background-color: themed('moduleBackgroundColor');
  }

  /* &:hover {
    @include MQ(L) {
      @include themify($themes) {
        box-shadow: 2px 2px 3px 0 themed('contentBorderAccent');
      }
    }
  }*/
}

.PostSummary {
  // padding: 0 0 0.5rem;
  margin: 0 0 1.25vw;
  clear: left;
  @include clearfix;

  .PostSummary__nsfw-warning {
    border: 1px solid $color-border-light;
    padding: 0.75rem 2rem;
    min-height: 80px;
    .PostSummary__footer {
      margin-top: 0.2rem;
      .Reblog__button {display: none;}
      .Voting__button {display: none;}
    }
    .PostSummary__time_author_category_small {
      margin-bottom: 0.2rem;
    }
  }

  .nsfw-flag {
    color: $color-red;
    border: 1px solid $color-red;
    font-size: 75%;
    border-radius: 3px;
    font-weight: normal;
    font-family: Arial;
    margin: 0 0.1rem;
    padding: 2px 5px;
  }
}

.PostSummary__image {
  cursor: pointer;
  float: left;
  width: 130px;
  height: 4.5rem;
  background-color: #EEE;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50% 20%;
  border: 1px solid #EEE;
}

.PostSummary__image-mobile {
  display: block;
  background: #F8F8F8;

  img {
    margin: 0 auto;
    display: block;
    max-width: 100%;
    max-height: 80vw;
    background: white;
    transform: translate3d(0, 0, 0);
  }
}

.PostSummary.with-image .PostSummary__reblogged_by,
.PostSummary.with-image .PostSummary__content {
  margin-left: 8.25rem;
}

.PostSummary__header {
  > h3 {
    font: 600 100% "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Arial, sans-serif;
    margin: 0;
    overflow: hidden;
    > a {
      color: #333;
    }
    > a:visited {
      color: #777;
    }
    .Icon {
      margin: 0 0.25rem;
      svg {
        width: 0.85rem;
        height: 0.85rem;
        vertical-align: 5%;
      }
    }
  }
  .nsfw-flag {
    margin-right: 0.25rem;
  }
}

.PostSummary__body {
  width: auto;
  padding: 0 0 0.15rem;
  font-size: 0.9rem;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  @include themify($themes) {
    color: themed('textColorPrimary');
  }
  overflow: hidden;
  line-height: 1.4;
  text-overflow: ellipsis;

  a {
    display: inline;
    @include themify($themes) {
      color: themed('textColorPrimary');
    }
  }
}

.layout-list .PostSummary__body {
  @include MQ(M) {
    white-space: nowrap;
  }
}

.PostSummary__footer {
  color: $dark-gray;
  font-weight: 400;
  path {
    fill: $dark-gray;
  }
  a {
    color: $dark-gray;
  }
  .Icon {
    position: relative;
    top: -1px;
  }
}

.PostSummary__time_author_category {
  color: $dark-gray;
  a {
    color: $dark-gray;
    font-weight: bold;
    @include hoverUnderline;
  }
  padding-left: 1rem;
  @include themify($themes) {
    border-left: themed('borderLight');
  }

  .Reblog__button {
    margin-right: 1rem;
    padding-right: 1rem;
    border-right: transparent;
  }
}

.PostSummary__time_author_category_small a {
  color: $dark-gray;
}

@media screen and (min-width: 39.9375em) {
  .PostSummary.with-image .PostSummary__reblogged_by .Icon.reblog {
    margin-left: -22px;
  }
}

.PostSummary__reblogged_by {
  .Icon path {
    fill: #cacaca;
  }
  color: $dark-gray;
  font-size: 90%;
  margin-top: -0.5rem;
  a {
    color: inherit;
    font-weight: bold;
    @include hoverUnderline;
  }
}

/* Small only */
@media screen and (max-width: 39.9375em) {
  .PostSummary {
    // padding: 1rem 0.5rem;
    margin-bottom: 0;
    border: none;
    // border-top: 1px solid $light-gray;
  }
  .PostSummary__image {
    float: none;
    margin-top: 0.5rem;
    // background-size: contain;
    display: block;
    width: 100%;
    height: 40vw;
  }
  .PostSummary__body {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    white-space: normal;
    text-overflow: initial;
    padding-bottom: 0.3rem;
    a {
      display: block;
    }
  }
  .PostSummary.with-image .PostSummary__reblogged_by,
  .PostSummary.with-image .PostSummary__content {
    margin-left: 0;
  }
  .PostSummary__footer .Voting, .PostSummary__footer .Voting__inner {
    border-right: none;
  }
  .PostSummary__time_author_category {
    margin-left: 0;
    border-left: 1px solid $color-border-light;
    .Reblog__button {
      margin-right: 1rem;
      padding-right: 1rem;
      border-right: none;
    }
  }
}

.articles__summary {
  .nsfw-flag {
    color: $color-red;
    border: 1px solid $color-red;
    font-size: 75%;
    border-radius: 3px;
    font-weight: normal;
    font-family: Arial;
    margin: 0 0.1rem;
    padding: 2px 5px;
    margin-right: 5px;
  }

  .articles__resteem,
  .articles__crosspost {
    padding-top: 0;
    margin-top: 0;
  }

  .articles__crosspost {
    padding: 5px 10px 5px 10px;
    margin: 5px 0;
    font-size: 0.85rem;
    border-radius: 3px;

    @include themify($themes) {
      background-color: themed('backgroundColor');
    }
  }

  .articles__crosspost-icon {
    margin-right: 5px;
  }

  .articles__crosspost-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .articles__feature-img img {
      width: 100%;
      max-height: 56.25vw;
      object-fit: cover;
  }

  .articles__content-block--text {
      width: 100%;
  }
}
