.ReplyEditor {
  max-width: 60rem;
  padding: 10px;
  margin-bottom: 15px;
  overflow-y: auto;
  @include themify($themes) {
    border-radius: themed('roundedCorners');
    border: themed('border');
    background-color: themed('moduleBackgroundColor');
  }
  &.side-by-side {
    max-width: 100%;
  }
  .preview-container {
    max-width: 48rem;
    margin-left: 5rem;
    &.side-by-side {
        max-width: 100%;
        margin-left: 0;
    }
  }
  .vframe {
    display: block;
    .public-DraftEditor-content {
      height: 20em;
      resize: vertical;
      color: #333;
    }
  }
  .button {
    @extend .e-btn;
    margin-bottom: 2em;
  }
  .button.hollow.no-border {
    background-color: transparent;
    box-shadow: 0 0 0 rgba(0,0,0,0.0);
    text-decoration: none;
    @extend .link;
    @extend .link--secondary;
    font-weight: normal;
    @include font-size(14px);
    outline: none;
    text-shadow: 0 0 0 rgba(0,0,0,0.0);
    border: transparent;
  }
  .side-by-side {
      height: calc(100vh - 140px);
  }
  .Preview-info {
    display: flex;
    justify-content: space-between;
    margin: 0.4em 0 1em;
  }
}

input.ReplyEditor__altAuthor {
    width: 300px;
}

@media screen and (max-width: 63.9375em) {
  .ReplyEditor .side-by-side {
    height: auto;
  }
}

.comment-editor .ReplyEditor .column {
  flex: 100%;
  max-width: 100%;
}

.comment-editor .ReplyEditor .side-by-side {
  height: auto;
}

.upload-enabled {
    border-bottom: 1px dashed #ddd;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.drag-and-drop {
    font-size: 85%;
    padding: 3px 8px;
    margin: 0;
    color: #767676;
    background-color: #fafafa;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-bottom-right-radius: 3;
    border-bottom-left-radius: 3;
}

.PostFull .ReplyEditor__body {
  margin: 1rem 0 0;
}

.ReplyEditor__title {
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem;
  height: auto;
}

.ReplyEditor__draft {
  position: fixed;
  bottom: 0;
  left: 0;
  background: rgba(255,255,255,0.9);
  border-radius: 5px;
  color: #BBB;
  font-size: 1.2em;
  text-align: center;
  z-index: 1;
  padding: 0.5rem 1rem;
}
.ReplyEditor__draft-hide {
  opacity: 0;
}
.ReplyEditor__draft-saved {
  -webkit-transition: opacity 3s ease-in-out;
  -moz-transition: opacity 3s ease-in-out;
  -ms-transition: opacity 3s ease-in-out;
  -o-transition: opacity 3s ease-in-out;
  transition: opacity 3s ease-in-out;
  opacity: 0;
}

.ReplyEditor .Preview {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.ReplyEditor .Preview .Markdown {
  border: 1px solid $light-gray;
  padding: 0 0.5rem;
}

.ReplyEditor__body {
  margin-top: 1rem;
  clear: both;
}

.ReplyEditor__options {
  font-size: 85%;
  select {
    font-size: 100%;
    width: 10rem;
    height: auto;
    padding: 0.25rem 0.5rem;
    margin-left: 0.25rem;
  }
  label {
    font-size: 100%;
    text-transform: none;
  }
}

.Dropdown__root___1B9ta {
  color: black!important;
}

.ReplyEditor__options__cover_image_selector {
    margin-top: 10px;
}

.ReplyEditor__options__image_selector {
    .ReplyEditor__options__image_selector__image_container {
        width: 60px;
        height: 60px;
        margin-right: 5px;
        margin-bottom: 5px;
        border: 1px solid grey;
        background-size: cover;
        display: inline-block;
        transition: width 0.5s, height 0.5s;
        vertical-align: top;
        opacity: 0.5;

        &.selected {
            opacity: 1;
            @include themify($themes) {
                border: 3px solid $color-hive-red;
            }
        }

        &:hover {
            opacity: 1;
            width: 120px;
            height: 120px;
        }
    }
}

.ReplyEditor--submit-buttons-container {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 63.9375em) {
        flex-direction: column;
    }
}
