.dropdown {
    position: relative;
    display: inline-block;
  
    .Icon.dropdown-arrow {
      top: 2px;
      margin-right: 0;
    }
  
    > .dropdown__content {
      visibility: hidden;
      min-width: 145px;
      z-index: 1000;
      display: block;
      border-radius: $global-radius;
      opacity: 0;
      position: absolute;
      top: 100%;
      padding: 2px;
  
      @include themify($themes) {
        background-color: themed('backgroundColor');
        border: themed('borderDark');
      }
      transform: translateY(10%);
      transition: all 0.3s ease 0s, visibility 0s linear 0.3s;
      box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  
    }
  
    &.show > .dropdown__content {
      visibility: visible;
      opacity: 1;
      transform: translateX(0%);
      transition-delay: 0s;
      a {
        transition: 0.2s all ease-in-out;
        &:hover {
          @include themify($themes) {
            color: themed('textColorAccent');  
          }
        }
      }
    }
  
    &.left > .dropdown__content {
      position: absolute;
      right: 0%;
    }
    &.right > .dropdown__content {
      position: absolute;
      left: 0%;
    }
  }
  