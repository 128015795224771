form.search-input {

    height: 42px;

    svg.search-input__icon {
        position: absolute;
        pointer-events: none;
        @include themify($themes) {
            stroke: themed('colorAccentReverse');
        }
        stroke-width: 1.2;
        fill: none;
    }

    input.search-input__inner {
        outline: none;
        padding: 9px 10px 11px 32px;
        border-radius: 25pc;
        transition: all 0.3s ease-in-out;
        font-size: 16px;
        background: transparent;
        box-shadow: none;
        width: 42px;
        height: 42px;
        color: transparent;
        cursor: pointer;
        font-family: $font-primary;
        border: 1px solid rgba(202, 202, 202, 0.6);

        &::placeholder {
            color: transparent
        }
        &:hover {
            background-color: $color-hive-red;
        }
        &:focus {
            width: 180px;
            @include themify($themes) {
                border: themed('moduleBackgroundColor');
            }
            border-color: $color-hive-red;
            // box-shadow: 0 0 5px rgba(109,207,246,.5);
            padding-left: 2.5rem;
            @include themify($themes) {
                color: themed('textColorPrimary');
            }
            background-color: transparent;
            cursor: auto;
            &::placeholder {
                color: $color-blue-dark;
                @include themify($themes) {
                    // color: themed('textColorSecondary');
                }
            }
        }
    }

    /* small */
    input.search-input__inner.search-input__inner--small {

    }
}

form.search-input--expanded {

    height: 42px;

    svg.search-input__icon {
        position: absolute;
        pointer-events: none;
        @include themify($themes) {
            stroke: themed('colorAccentReverse');
        }
        stroke-width: 1.2;
        fill: none;
    }

    &:hover {
        svg.search-input__icon {
            // stroke: $color-white;
        }
    }

    input.search-input__inner {
        outline: none;
        padding: 9px 10px 11px 32px;
        border-radius: 25pc;
        transition: all 0.3s ease-in-out;
        font-size: 16px;
        background: transparent;
        height: 42px;
        color: transparent;
        font-family: $font-primary;
        width: 100%;

        border-color: $color-hive-red;
        // box-shadow: 0 0 5px rgba(109,207,246,.5);
        padding-left: 2.5rem;
        cursor: auto;
        border: 1px solid rgba(202, 202, 202, 0.6);


        @include themify($themes) {
            color: themed('textColorPrimary');
        }
        &:hover {
            // background-color: $color-hive-red;
            // color: $color-white;
        }
        &::placeholder {
            @include themify($themes) {
                color: themed('textColorSecondary');
            }
        }
        &:hover::placeholder {
            // color: $color-text-white;
        }
    }

    /* small */
    input.search-input__inner.search-input__inner--small {

    }
}

.search-sort-order {
    margin-top: 10px;
}

.search-sort-order--title {
    margin-bottom: 3px;
}

.search-sort-order--select select {
    width: 200px;
}
