$menu-width: 250px;

.SidePanel {
  display: block;

  &__hide-signup {
      display: none;
  }

  .menu > li.last {
    border-bottom: 1px solid $color-border-dark;
    color: $color-text-gray-light;
  }

  > div {
    background-color: $color-hive-black-darkest;
    color: $color-white;
    padding-top: 3rem;
    .close-button {
      color: $color-white;
    }
    .menu > li {
      > a {
        transition: 0.2s all ease-in-out;
        border-top: 1px solid $color-hive-black;
        color: $color-white;
        border-bottom: 1px solid $color-hive-black-darkest;
      }
      > a:hover, &:focus {
        background-color:  $color-hive-black;
        border-bottom: 1px solid $color-hive-red;

        path {
          fill: $color-hive-red;
        }
      }
      path {
        fill: $color-text-gray-light;
      }
      a.menu-section {
        color: $color-text-gray-light;
      }
      a.menu-section:hover, a.menu-section:focus {
        cursor: default;
        background-color: inherit;
        border-bottom: 1px solid $color-hive-black-darkest;
      }
    }
    ul:nth-of-type(n+3) {
      margin-top: 2rem;
    }

    position: fixed;
    z-index: 1000;
    top: 0;
    width: $menu-width;
    height: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    transition: visibility 250ms, transform ease 250ms;

    &.left {
      left: -$menu-width;
    }

    &.visible.left {
      transform: translate3d($menu-width, 0, 0);
    }

    &.right {
      right: -$menu-width;
      visibility: hidden;
      overflow-y: auto;
    }

    &.visible.right {
      transform: translate3d(-$menu-width, 0, 0);
      visibility: visible;
    }
  }
  .Icon.extlink {
    position: relative;
    top: 3px;
    left: 2px;
  }
}

/* Small only */
@media screen and (max-width: 39.9375em) {
  .SidePanel {
    div ul:nth-of-type(n+2) {
      margin-top: 2rem;
    }
    > div > .menu > li > a {
      padding: 0.3rem 1rem;
    }
}
}
