.Userpic {
    display: inline-block;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 50%;

    width: 48px;
    height: 48px;
}

.Userpic__nft-badge {
    position: absolute;
    bottom: -8px;
    right: -8px;
    width: 32px;
    transition: all 250ms ease-in-out;
}

.Userpic__nft-badge:hover {
    bottom: -18px;
    right: -18px;
    width: 48px;
}



