.theme-light {
    /* http://jmblog.github.com/color-themes-for-google-code-highlightjs */

    /* Tomorrow Comment */
    .hljs-comment,
    .hljs-quote {
        color: #8e908c;
    }

    /* Tomorrow Red */
    .hljs-variable,
    .hljs-template-variable,
    .hljs-tag,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class,
    .hljs-regexp,
    .hljs-deletion {
        color: #c82829;
    }

    /* Tomorrow Orange */
    .hljs-number,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params,
    .hljs-meta,
    .hljs-link {
        color: #f5871f;
    }

    /* Tomorrow Yellow */
    .hljs-attribute {
        color: #eab700;
    }

    /* Tomorrow Green */
    .hljs-string,
    .hljs-symbol,
    .hljs-bullet,
    .hljs-addition {
        color: #718c00;
    }

    /* Tomorrow Blue */
    .hljs-title,
    .hljs-section {
        color: #4271ae;
    }

    /* Tomorrow Purple */
    .hljs-keyword,
    .hljs-selector-tag {
        color: #8959a8;
    }

    .hljs {
        display: block;
        overflow-x: auto;
        background: white;
        color: #4d4d4c;
        padding: 0.5em;
    }

    .hljs-emphasis {
        font-style: italic;
    }

    .hljs-strong {
        font-weight: bold;
    }

}

.theme-dark {
    /* Tomorrow Night Theme */
    /* http://jmblog.github.com/color-themes-for-google-code-highlightjs */
    /* Original theme - https://github.com/chriskempson/tomorrow-theme */
    /* http://jmblog.github.com/color-themes-for-google-code-highlightjs */

    /* Tomorrow Comment */
    .hljs-comment,
    .hljs-quote {
        color: #969896;
    }

    /* Tomorrow Red */
    .hljs-variable,
    .hljs-template-variable,
    .hljs-tag,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class,
    .hljs-regexp,
    .hljs-deletion {
        color: #cc6666;
    }

    /* Tomorrow Orange */
    .hljs-number,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params,
    .hljs-meta,
    .hljs-link {
        color: #de935f;
    }

    /* Tomorrow Yellow */
    .hljs-attribute {
        color: #f0c674;
    }

    /* Tomorrow Green */
    .hljs-string,
    .hljs-symbol,
    .hljs-bullet,
    .hljs-addition {
        color: #b5bd68;
    }

    /* Tomorrow Blue */
    .hljs-title,
    .hljs-section {
        color: #81a2be;
    }

    /* Tomorrow Purple */
    .hljs-keyword,
    .hljs-selector-tag {
        color: #b294bb;
    }

    .hljs {
        display: block;
        overflow-x: auto;
        background: #1d1f21;
        color: #c5c8c6;
        padding: 0.5em;
    }

    .hljs-emphasis {
        font-style: italic;
    }

    .hljs-strong {
        font-weight: bold;
    }

    .hljs-tag .hljs-attr, .hljs-tag .hljs-name {
        color: #bbbbbb;
    }
}
