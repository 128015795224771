.MarkdownViewer__negative_group {
    color: crimson;
    border-top: 1px solid $medium-gray;
    padding-top: 1rem;
}

.MarkdownViewer {
  max-width: 48rem;
  height: 100%;
  overflow-y: auto;
}

details {
  @include themify($themes) {
    background-color: themed('backgroundColorOpaque');
    outline: dotted 1px themed('backgroundColorOpaque');
  }
}
