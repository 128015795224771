.flex-row {
    flex-direction: row;
    display: flex;
}

.flex-column {
    flex-direction: column;
    display: flex;
}

.flex-body {
    display: flex;
}

.notification__filter {
    padding: 0 5px 0 5px;
    border-right: 1px solid #ababab;
}

.notification__filter:last-child {
    border-right: 0;
}

.notification__filter.hide {
    display: none;
}

.notification__filter.selected {
    font-weight: bold;
}

.notification__item.even {
    @include themify($themes) {
        background-color: themed('tableRowEvenBackgroundColor');
    }
}

.notification__item {
    @include themify($themes) {
        background-color: themed('tableRowOddBackgroundColor');
    }
    align-items: center;
    padding: 0.5em 1rem;
    margin-bottom: 2px;
    position: relative;

    .notification__unread {
        position: absolute;
        left: 0.4em;
        top: 0.75em;
        font-size: 2em;
        @include themify($themes) {
            color: themed('textColorAccent');
        }
    }

    .Userpic {
        margin: 0 1em;
    }

    .Icon {
        margin-right: 0.25em;
        @include themify($themes) {
            fill: themed('textColorSecondary');
            path {
                fill: themed('textColorSecondary');
            }
            circle {
                stroke: themed('textColorSecondary');
            }
        }
    }

    .notification__date {
        font-size: 0.9em;
        opacity: 0.8;
        @include themify($themes) {
            color: themed('textColorSecondary');
        }
    }

    .notification__message {
        padding-bottom: 0.5em;
        padding-right: 0.5em;
    }

    .notification__message a {
        @include themify($themes) {
            color: themed('textColorPrimary');
        }
    }

    .notification__message a:visited {
        @include themify($themes) {
            color: themed('textColorSecondary');
        }
    }

    .notification__score {
        position: absolute;
        top: 5px;
        right: 5px;
        height: 4px;
        width: 35px;
        background-color: lightgray;
    }

    .notification__score_bar {
        height: 100%;
        position: relative;
        background-color: $color-text-hive-red;
    }
}

