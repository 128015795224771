.UserProfile {
    margin-top: -1.5rem;

    .articles {
      @include MQ(M) {
        margin: 0 auto;
      }
    }
}

.UserProfile__blacklists {
    .account_warn {font-size: 0.8em;}
    .VerticalMenu {
        font-size: 1rem;
        text-shadow: none;
          @include themify($themes) {
            background-color: themed('backgroundColor');
            color: themed('textColorPrimary');
          }
    }
}

.UserProfile__postmenu {
    margin: -0.5rem 0 1rem;
    padding-bottom: 0.5rem;
    @include themify($themes) {
        border-bottom: themed('border');
    }
    div {display: inline-block; padding: 0 0.5rem; margin: 0 0.5rem;}
}

.UserProfile__tab_content {
    margin-top: 1.5rem;
}

.UserProfile__top-nav {
  background-color: $color-blue-dark;
  padding: 0;
  .menu {
    background-color: transparent;
  }
  .menu > li > a {
      transition: all 200ms ease-in;
      transform: translate3d( 0, 0, 0);
      padding-left: 0.7rem;
      padding-right: 0.7rem;
      background-color: transparent;
      color: $color-white;
      &:hover, &:focus {
        background-color: $color-hive-black;
      }
      &.active {
          @include themify($themes) {
            background-color: themed('backgroundColor');
            color: themed('textColorPrimary');
          }
          z-index: 2;
          font-weight: bold;
      }
      @media screen and (max-width: 39.9375em) {
          font-size: 0.85rem;
      }
  }

  div.UserProfile__top-menu {
      max-width: 71.42857rem;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      // Override default svg vertical alignment
      .Icon > svg, .Icon span.icon {
          vertical-align: middle!important;
      }

      .columns.small-9 {
          flex: 1 1 0px;
          max-width: 100%;
      }
  }
}

.UserProfile__section-title {
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #EEE;
}

.UserProfile__banner {
    text-align: center;
    color: $white;
    a {
        color: $white;
    }
    > div.column {
        background: $color-background-less-dark;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        text-shadow: 1px 1px 2px black;
        .button {text-shadow: none;}

        min-height: 155px;
    }
    h1 {
        padding-top: 20px;
        font-weight: 600;
        font-size: 1.84524rem!important;
        @media screen and (max-width: 39.9375em) {
            font-size: 1.13095rem!important;
        }
    }
    .Icon {
        margin-left: 1rem;
        svg {fill: #def;}
    }

    .UserProfile__rep {
        font-size: 80%;
        font-weight: 200;
    }

    .UserProfile__buttons {
        position: absolute;
        top: 15px;
        right: 5px;

        label.button {
            color: black;
            border-radius: 3px;
            background-color: white;
        }
    }

    .UserProfile__bio {
        margin: -0.4rem auto 0.5rem;
        font-size: 95%;
        max-width: 420px;
        line-height: 1.4;
    }
    .UserProfile__info {
        font-size: 90%;
    }

    .UserProfile__stats {
        margin-bottom: 5px;
        padding-bottom: 5px;
        font-size: 90%;

        a {
            @include hoverUnderline;
            vertical-align: middle;
	    }

        > span {
            padding: 0px 10px;
            border-left: 1px solid #CCC;
            &:first-child {border-left: none;}
            &:nth-child(5) {border-left: none; border-right: 1px solid #CCC}
        }
    }
}

@media screen and (max-width: 39.9375em) {

    div.UserProfile__top-nav .menu li>a {
        padding: 8px;
    }

    .UserProfile__top-menu > div.columns {
        padding-left: 0;
        padding-right: 0;
    }

    .UserProfile__banner .Userpic {
        width: 36px !important;
        height: 36px !important;
    }

    .UserProfile__banner .UserProfile__buttons {
        text-align: right;

        label.button {
            display: block;
        }
    }

    .UserProfile__banner .UserProfile__buttons_mobile {
        position: inherit;
        margin-bottom: .5rem;
        .button {
            background-color: $white;
            color: $black;
        }
    }
}

// Temporary fix to prevent alternate User Profile pages outside the blog from taking the narrow layout.

.UserProfile {
  .articles {
      margin-bottom: 4em;
    &__h1 {
      text-transform: none;
      @include MQ(M) {
        @include font-size(20px);
      }
    }
  }
  &__tab_content.layout-block.settings, &__tab_content.layout-block.curation-rewards, &__tab_content.layout-block.author-rewards {
    .articles {
        padding: 1.5em 1.5em;
        max-width: 1056px;
        @include MQ(XL) {
          min-width: 1050px;
        }
    }
  }
  .settings .articles__layout-selector, .curation-rewards .articles__layout-selector, .author-rewards .articles__layout-selector  {
      display: none;
    }
}

.UserProfile__Userpic {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 48px;
    margin-right: 0.75rem;
    vertical-align: middle;
}

.UserProfile__badge_image_hivebuzzlevel {
    width: 25px;
    height: 25px;
    top: 30px;
    left: 30px;
    transition: width 0.25s, height 0.25s, top 0.25s, left 0.25s;
    margin-left: 7px;

    &:hover {
        width: 40px;
        height: 40px;
        top: 26px;
        left: 26px;
    }
}

.UserProfile__banner .Icon.UserProfile__badge_image_twitter {
    margin-left: 0.5rem;
}

.UserProfile__banner .Icon.UserProfile__badge_image_twitter svg {
    width: 22px;
    height: 22px;
    fill: #1da1f2;
}
