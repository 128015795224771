.Rewards__chart {

    .head {font-size: 0.8em; margin: -6px 0 8px;}

    #reward_chart {
        background: white;
        position: relative;
        font-size: 10px;
        line-height: 1em;

        .box {
            position: absolute;
            box-shadow: inset 0 0 5px rgba(0,0,0,0.25);
            &:hover {
                box-shadow: inset 0 0 3px rgba(0,0,0,1);
            }
            &.box-a .title {font-style: italic}
            a.box-inner {
                display: block;
                padding: 2px;
                color: black;
                height: 100%;

                .title {
                    height: 100%;
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .detail {
                    display: none;
                    position: fixed;
                    bottom: 10px;
                    right: 10px;
                    width: 120px;
                    padding: 4px;
                    font-size: 12px;
                    line-height: 1.2em;
                    background: white;
                    border: 1px solid black;
                    z-index: 10;
                }
                &:hover .detail {display: block}
            }
        }
    }


}
