.CommunitiesIndex {
    table {margin-top: 1em;}
    table tbody {background: transparent;}
    table tbody tr {background: transparent !important;}
    table th {text-align: left; font-weight: normal}
    table th a.title {font-weight: 400; font-size: 1.3em;}
    table td {vertical-align: middle; color: #666; text-align: center;}
    table small {color: #999; display: block;}
    table .button {margin: 0}

    &.c-sidebar__module {
        /* Larger than Medium */
        @media screen and (min-width: 64.0625em) {
            padding: 1.5em 4em;
        }
    }

    table tbody {
        border: none;
    }

    table tr {
        @include themify($themes) {
            border: themed('border');
        }
    }

    table th {
        width: 600px;

        /* Larger than Small */
        @media screen and (min-width: 40.0625em) {
            padding-right: 6%;
        }
    }

    table td {
        width: 40px;
    }
}

.BadgesAchievements {
    .BadgesAchievements_tabs_container {
        padding: 5px 15px;
    }

    @media screen and (max-width: 39.9375em) {
        margin: 0;
    }

    a {
        display: inline-block;
    }

    .BadgesAchievements__badge_image {
        margin: 5px;
        img {
            vertical-align: top;
            width: 64px;
            @media screen and (max-width: 39.9375em) {
                width: 48px;
            }
        }
    }

    .react-tabs__tab-list {
        background-color: $color-background-off-white;
    }

    .react-tabs__tab {
        color: $color-text-dark;
        font-weight: bold;
    }

    .react-tabs__tab--selected {
        background-color: $color-blue-dark;
        border-color: transparent;
        border-radius: 0;
        color: white;
    }

    .react-tabs__tab-panel {
        min-height: 58px;
    }
}

.article_section {
    margin-bottom: 40px;
}
