.post_advanced_settings {
    h4 {
        @media print, screen and (min-width: 40em) {
            font-size: 1.250rem;
        }
    }
}

.post_advanced_settings .simpilepicker-date-picker {
    width: 341px;
}

.post_advanced_settings  .datetimepicker_value {
    cursor: pointer;
}

.post_advanced_settings .simplepicker-wrapper.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1000;
}
